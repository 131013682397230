import React from 'react';
import SaloPic from '../../assets/pictures/Salopic.jpg';

const LoadingScreen = ({title}) => {
  return (
    <div style={{position: "absolute", top:0,left:0,right:0,bottom:0, zIndex: 500, backgroundColor: "white"}}>
      <div style={{justifyContent: "center", alignItems: "center", width: "100%"}}>
        <img 
        style={{
          // marginLeft: "min(30%)", 
          width: "min(40%, 300px)", 
          // marginTop: 100,
          position: "fixed",
          margin: "auto",
          // marginTop: 100,
          // margin: "100px auto",
          left: 0,
          right: 0,
          top: 100
        }} 
        src={SaloPic} />
        <div style={{
          position: "fixed",
          left: 0,
          right: 0,
          marginTop: "min(80%, 400px)",
        }}>
          <h1 style={{fontSize: "min(7vw,50px)", textShadow: "0px 0px 5px 5px black", fontWeight: "bold", color: "rgb(154,174,87)", flex: 1, textAlign: 'center'}}>{title}</h1>
          <h1 style={{position: "relative", fontSize: 20, bottom: 2, flex: 1, textAlign: 'center'}}>Ladataan</h1>
        </div>
      </div>
    </div>
  )
}

export default LoadingScreen;