import React, {useState} from 'react';
import * as BsIcons from 'react-icons/bs';

const MapKohdeCard = ({title, id, description, coords, url, img, toggleInfoOn, moveToPoint, setCurrentInfo, type, selected}) => {

  return (
    <div style={{paddingRight: 10}}>
    <div
    onClick={() => {
      setCurrentInfo(title, url, id, description, type);
      moveToPoint(id, coords);
    }}
    style={{
      backgroundColor: selected?"rgba(13, 104, 139, 0.9)":"rgba(13, 104, 139, 0.45)",
      height: "100%",
      minWidth: 163,
      maxWidth: 163,
      borderRadius: 12,
      margin: "0px 0px 0px 0px",
      padding: 0,
      //boxShadow: "2px 2px 10px black",
    }}>

      <BsIcons.BsInfoCircle
        onClick={toggleInfoOn}
        size={30}
        //color={"grey"}
        color={"white"}
        style={{cursor: 'pointer', float:"right", marginRight: 10, marginTop: 7}}/>
      <img
        src={img}
        style={{
          position: 'relative',
          width: "40%",
          left: "30%",
          marginTop: 30
        }}
        />
      <p
        style={{
          color:"white",
          position: 'relative',
          //top: 5,
          verticalAlign: "middle",
          top: 25-(title.length*0.5),
          height: 60,
          overflow: 'scroll',
          textAlign: 'center',
          //margin: "10px 5px 0px 5px",
          //fontSize: 18-(title.length*0.1),
          fontSize: 14,
          fontWeight: 550
        }}
        >
      {title}
     </p>
    </div>
    </div>
  )
}

export default MapKohdeCard;
